import { Icon } from 'components/ui/Icon'
import { FC } from 'react'
import { useTheme } from 'styled-components'
import { palette } from 'styles/theme/palette'

import { Props } from './props'
import { Wrapper } from './styles'

export const Loader: FC<Props> = ({ size = 48, color = palette.Core.WHITE }) => {
  const theme = useTheme()

  return (
    <Wrapper size={size}>
      <Icon icon={theme.icons.loader.Loader} size={size} color={color} />
    </Wrapper>
  )
}
