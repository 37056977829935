export const routesBreadcrumbsSchema = `{
  "@context": "http://schema.org/",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Все станции по направлениям",
      "item": "https://bile-bile.kz/napravleniya"
    }
  ]
}`
