import { css } from 'styled-components'
import { isNumber } from 'utilities/isNumber'
import { pixelize } from 'utilities/pixelize'

export interface GridContainerParams {
  inlineGrid?: boolean
  cols?: number | string
  rows?: number | string
  areas?: string
  gap?: string | number
  rowGap?: string | number
  columnGap?: string | number
  justify?: 'start' | 'end' | 'center' | 'stretch'
  align?: 'start' | 'end' | 'center' | 'stretch'
  justifyContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  alignContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  autoColumns?: string
  autoRows?: string
  autoFlow?: 'row' | 'column' | 'dense'
}

export const gridContainer = ({
  inlineGrid = false,
  cols,
  rows,
  areas,
  gap,
  rowGap,
  columnGap,
  justify,
  align,
  alignContent,
  justifyContent,
  autoColumns,
  autoRows,
  autoFlow,
}: GridContainerParams = {}) => css`
  display: ${inlineGrid ? 'inline-grid' : 'grid'};
  ${cols ? `grid-template-columns: ${isNumber(cols) ? `repeat(${cols}, 1fr)` : cols};` : ''}
  ${rows ? `grid-template-rows: ${isNumber(rows) ? `repeat(${rows}, 1fr)` : rows};` : ''}
    ${areas ? `grid-template-areas: ${areas}` : ''}
    ${gap ? `gap: ${isNumber(gap) ? pixelize(gap) : gap};` : ''}
    ${rowGap ? `row-gap: ${isNumber(rowGap) ? pixelize(rowGap) : rowGap};` : ''}
    ${columnGap ? `column-gap: ${isNumber(columnGap) ? pixelize(columnGap) : columnGap};` : ''}
    ${justify ? `justify-items: ${justify};` : ''}
    ${align ? `align-items: ${align};` : ''}
    ${justifyContent ? `justify-content: ${justifyContent};` : ''}
    ${alignContent ? `align-content: ${alignContent};` : ''}
    ${autoColumns ? `grid-auto-columns: ${autoColumns};` : ''}
    ${autoRows ? `grid-auto-rows: ${autoRows};` : ''}
    ${autoFlow ? `grid-auto-flow: ${autoFlow};` : ''}
`
