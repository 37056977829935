export const orgSchema = `{
  "@context": "http://schema.org/",
  "@type": "Organization",
  "name": "Bile-bile",
  "logo": "https://bile-bile.kz/og-image.png",
  "url": "https://bile-bile.kz/",
  "address": {
    "@type": "PostalAddress",
    "addressCountry": "Kazakhstan"
  },
  "sameAs": []
}`
