export const webSchema = `{
  "@context": "http://schema.org/",
  "@type": "WebSite",
  "url": "https://bile-bile.kz/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://bile-bile.kz/search?origin={search_term_string}",
    "query-input": "required name=search_term_string"
  }
}`
