export const buildLandingSchema = ({ origin, destination }: Record<string, string>) => `{
  "@context": "http://schema.org/",
  "@type": "WebPage",
  "url": "https://bile-bile.kz/",
  "name": "Купить дешевые ж/д билеты по направлению ${origin} - ${destination} | bile-bile.kz",
  "description": "Bile-bile.kz ➤ дешевые ж/д билеты по направлению ${origin} - ${destination} ➤ Узнайте расписание, цены и покупайте железнодорожные билеты по выгодной цене.",
  "publisher": {
    "@type": "ProfilePage",
    "name": "Bile-bile.kz - ТОО «Nur-Kassa»"
  }
}`
