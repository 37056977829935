import { css } from 'styled-components'

export interface GridItemParams {
  columnStart?: number | string
  columnEnd?: number | string
  rowStart?: number | string
  rowEnd?: number | string
  area?: string
  justify?: 'start' | 'end' | 'center' | 'stretch'
  align?: 'start' | 'end' | 'center' | 'stretch'
}

export const gridItem = ({
  columnStart,
  columnEnd,
  rowStart,
  rowEnd,
  area,
  justify,
  align,
}: GridItemParams = {}) => css`
  ${columnStart ? `grid-column-start: ${columnStart};` : ''}
  ${columnEnd ? `grid-column-end: ${columnEnd};` : ''}
  ${rowStart ? `grid-row-start: ${rowStart};` : ''}
  ${rowEnd ? `grid-row-end: ${rowEnd};` : ''}
  ${area ? `grid-area: ${area};` : ''}
  ${justify ? `justify-self: ${justify};` : ''}
  ${align ? `align-self: ${align};` : ''}
`
