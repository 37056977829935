/* eslint-disable react/no-danger */
import Head from 'next/head'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import * as schemas from 'schemas/org'

export type PageType =
  | 'home'
  | 'schedule'
  | 'faq'
  | 'covid'
  | 'contacts'
  | 'search'
  | 'searchMulti'
  | 'privacyPolicy'
  | 'publicOffer'
  | 'paymentSuccess'
  | 'paymentFailure'
  | 'otp'
  | 'bookingPassengers'
  | 'bookingHistory'
  | 'bookingRefund'
  | 'landing'
  | 'routes'

interface Props {
  page: PageType
  extraParams?: Record<string, string>
}

const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'https://bile-bile.kz'
const KZ_SITE_URL = `${SITE_URL}/kk`

const getSchemasByPage = (page: PageType, extra: Record<string, string>) => {
  if (page === 'home') return [schemas.homeBreadcrumbsSchema, schemas.orgSchema, schemas.webSchema]
  if (page === 'faq') return [schemas.faqSchema, schemas.faqBreadcrumbsSchema]
  if (page === 'covid') return [schemas.covidBreadcrumbsSchema]
  if (page === 'contacts') return [schemas.contactsBreadcrumbsSchema]
  if (page === 'search' || page === 'searchMulti') return [schemas.buildSearchSchema(extra)]
  if (page === 'landing') return [schemas.buildLandingSchema(extra)]
  if (page === 'routes') return [schemas.routesBreadcrumbsSchema]
  return []
}

export const MetaHead: FC<Props> = ({ page, extraParams = {} }) => {
  const { t, lang } = useTranslation('meta')
  const router = useRouter()
  const title = t(`${page}.title`, extraParams)
  const description = t(`${page}.description`, extraParams)

  const pageSchemas = getSchemasByPage(page, extraParams)

  return (
    <Head>
      <title key='title'>{title}</title>
      <meta charSet='utf-8' />
      <meta name='description' content={description} key='meta-description' />
      <meta property='og:title' content={title} key='og-title' />
      <meta property='og:type' content='website' key='og-website' />
      <meta property='og:description' content={description} key='og-description' />
      <meta
        property='og:url'
        content={`${lang === 'ru' ? SITE_URL : KZ_SITE_URL}${router.asPath}`}
        key='og-url'
      />
      <meta property='og:image' content={`${SITE_URL}/og-image.png`} key='og-image' />

      {pageSchemas.map((schema, idx) => (
        <script
          id={`schema-${idx}`}
          key={schema}
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(JSON.parse(schema)) }}
        />
      ))}
    </Head>
  )
}
