import { css } from 'styled-components'

export const pageWrapper = (useFullHeight = true, mt = 90, mb = 96) => css`
  padding-top: ${mt}px;
  position: relative;
  min-height: calc(${useFullHeight ? '100vh' : 'calc(100vh - 90px)'});
  margin-bottom: ${mb}px;

  @media screen and (max-width: 1180px) {
    padding-top: 80px;
  }
`
