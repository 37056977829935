import { Loader } from 'components/ui/Loader'
import { Typography } from 'components/ui/Typography'
import { FC } from 'react'
import { DefaultTheme, useTheme } from 'styled-components'
import { AnyColor } from 'styles/theme/palette'

import { ButtonFill, Props } from './props'
import { ButtonContent, LoaderWrapper, Wrapper } from './styles'

const getTypographyColor = (fill: ButtonFill, theme: DefaultTheme, textColor?: AnyColor) => {
  if (textColor) return textColor
  return fill === 'contained' ? theme.palette.text.ALTERNATIVE : theme.palette.text.MAIN
}

export const Button: FC<Props> = ({
  children,
  fill = 'contained',
  variant = 'regular',
  size = 'default',
  textColor,
  isLoading = false,
  color,
  type = 'button',
  ...props
}) => {
  const theme = useTheme()

  const typographyColor = getTypographyColor(fill, theme, textColor)

  return (
    <Wrapper
      fill={fill}
      variant={variant}
      size={size}
      isLoading={isLoading}
      color={color}
      type={type}
      {...props}
    >
      <ButtonContent isLoading={isLoading}>
        {typeof children === 'string' && (
          <Typography
            className='button-typography'
            color={typographyColor}
            typography={theme.typography.variants.button.MAIN}
            title={children}
          >
            {children}
          </Typography>
        )}
        {typeof children !== 'string' && children}
      </ButtonContent>
      <LoaderWrapper isLoading={isLoading}>
        <Loader color={typographyColor} />
      </LoaderWrapper>
    </Wrapper>
  )
}
