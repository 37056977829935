import { lighten, transparentize } from 'polished'
import styled, { css } from 'styled-components'
import { indent } from 'styles/mixins'
import { AnyColor } from 'styles/theme/palette'

import { ButtonFill, ButtonSize, Props } from './props'

const coreButtonStyles = () => css`
  position: relative;
  border: 1px solid;
  transition: 0.3s ease;
  cursor: pointer;
`

const hoverButtonStyles = () => css`
  .button-typography {
    color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
  }
`

const activeButtonStyles = () => css`
  .button-typography {
    color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
  }
`

const disabledButtonStyles = (fill?: ButtonFill) => css`
  pointer-events: none;
  cursor: not-allowed;
  border-color: ${({ theme }) => theme.palette.core.LIGHT_GRAY};
  background: ${({ theme }) =>
    fill === 'outlined' ? theme.palette.core.TRANSPARENT : theme.palette.core.LIGHT_GRAY};
  .button-typography {
    color: ${({ theme }) =>
      fill === 'outlined' ? theme.palette.text.DEEP_GRAY : theme.palette.text.ALTERNATIVE};
  }
`

const loadingButtonStyles = () => css`
  pointer-events: none;
  cursor: not-allowed;
`

const typographyStyles = () => css`
  line-height: 1;
  user-select: none;
  transition: all 0.3s ease;
`

const flatVariantStyles = () => css`
  ${indent('padding', { vertical: 8.5, horizontal: 35 })}
  border-radius: 5px;
`

const regularVariantStyles = (size?: ButtonSize) => css`
  ${indent('padding', {
    top: size === 'sm' ? 8 : 14,
    left: size === 'xl' ? 58 : 30,
    right: size === 'xl' ? 58 : 30,
    bottom: size === 'sm' ? 8 : 14,
  })}
  border-radius: 12px;
`

const textVariantStyles = () => css`
  padding: 0;
`

const hoverStyles = (hoverColor: AnyColor) => css`
  background-color: ${hoverColor};
  border-color: ${hoverColor};
  box-shadow: 0 8px 10px ${transparentize(0.86, hoverColor)},
    0 3px 14px ${transparentize(0.88, hoverColor)}, 0 4px 5px ${transparentize(0.8, hoverColor)};
`

const activeStyles = (hoverColor: AnyColor) => css`
  background-color: ${lighten(0.1, hoverColor)};
  border-color: ${lighten(0.1, hoverColor)};
  box-shadow: none;
`

export const Wrapper = styled.button<Props>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  ${coreButtonStyles()}
  ${({ variant, size }) => {
    if (variant === 'flat') return flatVariantStyles()
    if (variant === 'text') return textVariantStyles()
    return regularVariantStyles(size)
  }}
  ${({ fill, color: rawColor, hoverColor: rawHoverColor, theme, variant }) => {
    if (variant === 'text')
      return css`
        border: none;
        background: none;
      `

    const color = rawColor || theme.palette[fill === 'outlined' ? 'primary' : 'secondary'].MAIN
    const hoverColor = rawHoverColor || theme.palette.secondary.MAIN

    const effects = css`
      &:hover {
        ${hoverStyles(hoverColor)}
      }
      &:active {
        ${activeStyles(hoverColor)}
      }
    `

    if (fill === 'outlined')
      return css`
        border-color: ${color};
        background-color: ${theme.palette.core.TRANSPARENT};
        ${effects}
      `

    return css`
      border-color: ${color};
      background-color: ${color};
      ${effects}
    `
  }}
  
  &:hover {
    ${hoverButtonStyles()}
  }

  &:active {
    ${activeButtonStyles()}
  }

  &[disabled] {
    ${({ fill }) => disabledButtonStyles(fill)}
  }

  ${({ isLoading }) => isLoading && loadingButtonStyles()}

  .button-typography {
    ${typographyStyles()}
  }
`

interface ButtonContentProps {
  isLoading?: boolean
}

export const ButtonContent = styled.div<ButtonContentProps>`
  transition: all 0.3s ease;

  ${({ isLoading }) =>
    isLoading &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`

interface LoaderWrapperProps {
  isLoading?: boolean
}

export const LoaderWrapper = styled.div<LoaderWrapperProps>`
  position: absolute;
  transition: all 0.3s ease;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;

  ${({ isLoading }) =>
    isLoading &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`
