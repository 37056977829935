import styled from 'styled-components'
import { gridContainer } from 'styles/mixins'
import { makeElementSize } from 'utilities/makeElementSize'

import { Props } from './props'

export const Wrapper = styled.div<Props>`
  width: ${({ width }) => makeElementSize(width)};
  height: ${({ height }) => makeElementSize(height)};
  ${gridContainer}
`
