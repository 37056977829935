export const buildSearchSchema = ({
  origin,
  destination,
  departureTime,
  arrivalTime,
}: Record<string, string>) => `{
  "@context": "https://schema.org",
  "@type": "TrainReservation",
  "reservationId": "AB3XY2",
  "reservationStatus": "https://schema.org/ReservationConfirmed",
  "reservationFor": {
    "@type": "TrainTrip",
    "departureStation": {
      "@type": "TrainStation",
      "name": "${origin}"
    },
    "departureTime": "${departureTime}",
    "arrivalStation": {
      "@type": "TrainStation",
      "name": "${destination}"
    },
    "arrivalTime": "${arrivalTime}"
  }
}`
